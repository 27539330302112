export const errorInfoMixin = {
  methods: {
    /**
     * create Error Info
     * @param {string} message Error message
     * @param {number} count Error count
     * @return {{count, error: boolean, message}}
     */
    createErrorInfo(message, count) {
      return {
        error: count > 0,
        message: message,
        count: count
      };
    }
  }
};
