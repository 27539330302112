<template>
  <v-form v-bind="$attrs" v-on="$listeners" ref="form">
    <v-container fluid>
      <v-row dense>
        <!-- name -->
        <v-col cols="12" md="6">
          <v-text-field
            v-model="taskName"
            :label="nameLabel"
            :hint="nameHint"
            dense
            :rules="requiredRule"
          ></v-text-field>
        </v-col>

        <!-- priority -->
        <v-col cols="12" md="6">
          <v-select
            v-model="priority"
            class="mt-2"
            dense
            item-text="text"
            item-value="value"
            :items="priorityList"
            :label="priorityLabel"
            :hint="priorityHint"
            :rules="requiredRule"
          ></v-select>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" md="6">
          <!-- Assignment -->
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header disable-icon-rotate>
                {{ assignmentTitle }}

                <template v-slot:actions>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        right
                        v-on="on"
                        v-show="!assignee"
                        :color="colorRecordDeletedClass"
                        >{{ iconWarning }}</v-icon
                      >
                    </template>
                    <span>{{ userErrorMessage }}</span>
                  </v-tooltip>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container fluid>
                  <!-- Assignment methods -->
                  <v-select
                    v-model="assignmentMethod"
                    item-text="name"
                    item-value="method"
                    :items="assignmentMethods"
                    :disabled="!enabledAssignmentMethod"
                    :label="assignmentModeLabel"
                    :hint="assignmentModeHint"
                  ></v-select>

                  <!-- roles -->
                  <v-select
                    v-model="roles"
                    :items="roleList"
                    :label="roleLabel"
                    :hint="rolesHint"
                    :disabled="!enabledAssignmentRoles"
                    :error="errorRolesHasError"
                    :error-count="errorRolesCount"
                    :error-messages="errorRolesMessage"
                    multiple
                    chips
                    deletable-chips
                  ></v-select>

                  <!-- assignee -->
                  <v-select
                    v-model="assignee"
                    :disabled="!enabledUserList"
                    :items="userList"
                    :label="assigneeLabel"
                    :hint="usersHint"
                    :error="errorAssigneeHasError"
                    :error-count="errorAssigneeCount"
                    :error-messages="errorAssigneeMessage"
                    :loading="loadingUsers"
                    :rules="requiredRule"
                    required
                  ></v-select>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>

        <!-- duration -->
        <v-col cols="12" md="6">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>
                {{ durationTitle }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container fluid>
                  <!-- duration -->
                  <v-text-field
                    v-model="duration"
                    v-if="!isMilestone"
                    dense
                    :disabled="durationFixed"
                    :label="durationLabel"
                    :hint="durationHint"
                  ></v-text-field>
                  <!-- calendar -->
                  <v-select
                    v-model="calendar"
                    v-if="!isMilestone"
                    dense
                    class="mt-2"
                    :label="calendarLabel"
                    :items="workflowCalendars"
                    :hint="calendarHint"
                    :disabled="durationFixed"
                  ></v-select>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
  <!--  <div>User Task: {{ taskName }} (id: {{ taskId }})</div>-->
</template>

<script>
import { newProjectTaskMixin } from "@/mixins/shared/workflow/project/newProjectTaskMixin";

export default {
  name: "NewProjectTask",
  inheritAttrs: false,
  mixins: [newProjectTaskMixin]
};
</script>
