// model
import {
  assignmentMethod,
  assignmentMethods,
  findAssignmentMethod,
  taskPriorities
} from "@/model/workflow/task/taskModel";
import { colorRecordDeletedClass } from "@/model/record/recordModel";

// vuex
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("project");

// mixins
import { errorInfoMixin } from "@/mixins/shared/error/errorInfoMixin";
import { iconWarning } from "@/design/icon/iconConst";

export const newProjectTaskMixin = {
  mixins: [errorInfoMixin],
  data() {
    return {
      /**
       * priority List
       * @type {{Number}[]}
       */
      priorityList: taskPriorities,

      /**
       * role List
       * @type {{string}[]|[]}
       */
      roleList: [],

      /**
       * user List
       * @type {{string}[]|[]}
       */
      userList: [],

      /**
       * assignment Methods
       * @type {{Number}[]}
       */
      assignmentMethods: assignmentMethods,

      /**
       * a flag indicates whether workflow users are being loading
       */
      loadingUsers: false,

      /**
       * error Assignee
       * @type {{error: boolean, message :string, count: number}}
       */
      errorAssignee: this.createErrorInfo(undefined, 0),

      /**
       * error Roles
       * @type {{error: boolean, message :string, count: number}}
       */
      errorRoles: this.createErrorInfo(undefined, 0),

      /**
       * error Calendars
       * @type {{error: boolean, message :string, count: number}}
       */
      errorCalendars: this.createErrorInfo(undefined, 0),

      /**
       * iconWarning
       *  @type {String}
       */
      iconWarning: iconWarning,

      /**
       * Color Deleted Class (red)
       *  @type {String}
       */
      colorRecordDeletedClass: colorRecordDeletedClass
    };
  },

  props: {
    /**
     * User task
     * @type {{id: number, name: string, sequence: number, duration: {duration: string, calendar: string, fixed: boolean, milestone: boolean}, priority: number, reassignment: number, assignment: {assignee: string, fixed: boolean, method: number, roles: string}}}
     */
    task: undefined
  },

  computed: {
    ...mapState({
      /**
       * Workflow Roles
       * @return {{string}[]}
       **/
      workflowRoles: "workflowRoles",

      /**
       * workflow Calendars
       * @return {{string}[]}
       */
      workflowCalendars: "workflowCalendars"
    }),

    /**
     * Hint for Name Field
     * @return {string}
     */
    nameHint() {
      return "Display name of Task";
    },

    /**
     * Hint for Duration Field
     * @return {string}
     */
    durationHint() {
      return "Task Duration in Days";
    },

    /**
     * Hint for Task calendar
     * @return {string}
     */
    calendarHint() {
      return "Task calendar";
    },

    /**
     * Hint for Assignment Mode Field
     * @return {string}
     */
    assignmentModeHint() {
      return "Select User Task's Assignment Method";
    },

    /**
     * Hint for Users Field
     * @return {string}
     */
    usersHint() {
      return "User assigned to task";
    },

    /**
     * Hint for Roles Field
     * @return {string}
     */
    rolesHint() {
      return "Only Users from selected Roles can be assigned";
    },

    /**
     * Hint for Priority Field
     * @return {string}
     */
    priorityHint() {
      return "Priority Level of Task";
    },

    /**
     * Label for Name Field
     * @return {string}
     */
    nameLabel() {
      return " Name";
    },

    /**
     * Label for Duration Field
     * @return {string}
     */
    durationLabel() {
      return "Duration";
    },

    /**
     * calendar Label
     * @return {string}
     */
    calendarLabel() {
      return "Calendar";
    },

    /**
     * Label for Assignment Mode Field
     * @return {string}
     */
    assignmentModeLabel() {
      return "Method";
    },

    /**
     * Label for Users/assignee Field
     * @return {string}
     */
    assigneeLabel() {
      return "User";
    },

    /**
     * Label for Roles Field
     * @return {string}
     */
    roleLabel() {
      return "Roles";
    },

    /**
     * Label for Priority Field
     * @return {string}
     */
    priorityLabel() {
      return "Priority";
    },

    /**
     * Title for Assignment
     * remarks: e.g., summarize the context of expansion-panel beautifully
     * @return {string}
     */
    assignmentTitle() {
      if (this.assignee) {
        const assignment = this.fixedAssignment ? "Fixed Assigned" : "Assigned";
        return `${assignment} to ${this.assignee}`;
      }

      return "Not assigned!";
    },

    /**
     * Task Id
     * @return {number}
     */
    taskId() {
      return this.task?.id ?? -1;
    },

    /**
     * task name
     * @type {{string}}
     */
    taskName: {
      get() {
        return this.task?.name ?? "";
      },
      set(value) {
        if (this.task) {
          this.task.name = value || "";
        }
      }
    },

    /**
     * assignee
     * @type {{string}}
     */
    assignee: {
      get() {
        return this.task?.assignment?.assignee;
      },
      set(value) {
        if (this.task?.assignment) {
          this.task.assignment.assignee = value;
        }
      }
    },

    /**
     * assignment Method
     * @type {{number}} assignment method
     */
    assignmentMethod: {
      get() {
        return this.task?.assignment?.method ?? assignmentMethod.auto;
      },
      set(value) {
        if (this.task?.assignment) {
          this.task.assignment.method = value;
        }
      }
    },

    /**
     * determines whether to enable Assignment Method
     * @return {boolean} true if Assignment Method is enabled
     */
    enabledAssignmentMethod() {
      return !this.fixedAssignment;
    },

    /**
     * compute whether Assignment is fixed
     * (predefined by project template and marked as fixed)
     * @return {boolean|boolean} true if Assignment is fixed
     */
    fixedAssignment() {
      return this.task?.assignment?.fixed ?? false;
    },

    /**
     * roles
     * @type {{string}}[]
     */
    roles: {
      get() {
        return this.task?.assignment?.roles?.split(",") ?? [];
      },
      set(value) {
        if (this.task) {
          this.task.assignment.roles = value.join(",");
        }
      }
    },

    /**
     * determines whether to enable Assignment Roles
     * @return {boolean} true if Assignment Roles is enabled
     */
    enabledAssignmentRoles() {
      return !this.fixedAssignment;
    },

    /**
     * task priority
     * @type {{number}}
     */
    priority: {
      get() {
        return this.task?.priority;
      },
      set(value) {
        if (this.task) {
          this.task.priority = value;
        }
      }
    },

    /**
     * task duration
     * @type {{string}}
     */
    duration: {
      get() {
        return this.task?.duration?.duration;
      },
      set(value) {
        if (this.task.duration) {
          this.task.duration.duration = value;
        }
      }
    },

    /**
     * Title for Assignment
     * remarks: e.g., summarize the context of expansion-panel beautifully
     * @return {string}
     */
    durationTitle() {
      const duration = this.isMilestone ? "Milestone" : "Duration";
      return this.isMilestone ? duration : `${duration} ${this.duration}`;
    },

    /**
     * duration calendar
     * @type {string}
     */
    calendar: {
      get() {
        return this.task?.duration?.calendar;
      },
      set(value) {
        if (this.task.duration) {
          this.task.duration.calendar = value;
        }
      }
    },

    /**
     * is fixed duration (cannot be changed)
     * @return {boolean}
     */
    durationFixed() {
      return this.task?.duration?.fixed ?? false;
    },

    /**
     * is task a milestone
     * @return {boolean} true if task is a milestone
     */
    isMilestone() {
      return this.task?.duration?.milestone ?? false;
    },

    /**
     * determines whether to enable User List
     * @return {boolean} if true, enabled User List
     */
    enabledUserList() {
      return (
        this.assignmentMethod === assignmentMethod.manual &&
        !this.fixedAssignment
      );
    },

    /**
     * error Assignee Has Error
     * @return {boolean|boolean}
     */
    errorAssigneeHasError() {
      return this.errorAssignee?.error ?? false;
    },

    /**
     * error Assignee Count
     * @return {number|number}
     */
    errorAssigneeCount() {
      return this.errorAssignee?.count ?? 0;
    },

    /**
     * error Assignee Message
     * @return {string}
     */
    errorAssigneeMessage() {
      return this.errorAssignee?.message;
    },

    /**
     * error Assignee Has Error
     * @return {boolean|boolean}
     */
    errorRolesHasError() {
      return this.errorRoles?.error ?? false;
    },

    /**
     * error Assignee Count
     * @return {number|number}
     */
    errorRolesCount() {
      return this.errorRoles?.count ?? 0;
    },

    /**
     * error Assignee Message
     * @return {string}
     */
    errorRolesMessage() {
      return this.errorRoles?.message;
    },

    /**
     * Fields Required Rule
     * @return {(function(*=))[]}
     */
    requiredRule() {
      return [v => !!v || "Field is required"];
    },

    /**
     * User Error Message
     * @return {string}
     */
    userErrorMessage() {
      return "User Not Assigned";
    }
  },
  methods: {
    ...mapActions({
      /**
       * set Workflow Roles
       */
      setWorkflowRoles: "setWorkflowRoles",

      /**
       * Get Workflow User per provided Role
       */
      getWorkflowUsers: "getWorkflowUsers",

      /**
       * set Workflow Calendars
       */
      setWorkflowCalendars: "setWorkflowCalendars"
    }),

    /**
     * populate Role List
     * @return {Promise<void>}
     */
    async populateRoleList() {
      try {
        this.errorRoles = undefined;
        this.roleList =
          (this.workflowRoles?.length ?? 0) > 0
            ? this.workflowRoles
            : (await this.setWorkflowRoles()) ?? [];
      } catch (e) {
        console.error(e);
        this.errorRoles = this.createErrorInfo(e, 1);
      }
    },

    /**
     * populate Calendars
     * @return {Promise<void>}
     */
    async populateCalendars() {
      try {
        this.errorCalendars = undefined;

        if ((this.workflowCalendars?.length ?? 0) === 0) {
          await this.setWorkflowCalendars();
        }
      } catch (e) {
        console.error(e);
        this.errorCalendars = this.createErrorInfo(e, 1);
      }
    },

    /**
     * populate User List
     * @return {Promise<void>}
     */
    async populateUserList() {
      try {
        this.userList = [];
        this.errorAssignee = undefined;

        switch (this.assignmentMethod) {
          case assignmentMethod.manual: {
            try {
              this.loadingUsers = true;

              const roles = this.task?.assignment?.roles ?? "";
              if (roles) {
                const response = await this.getWorkflowUsers(roles);
                this.userList.push(...(response?.data ?? []));
              }

              // auto-select the first listed assignee
              if ((this.userList?.length ?? 0) > 0) {
                if (this.assignee) {
                  const user = this.userList.find(el => el === this.assignee);
                  if (!user) {
                    this.assignee = undefined;
                  }
                }
              } else {
                this.assignee = undefined;
              }
            } catch (e) {
              console.error(e);
              this.errorAssignee = this.createErrorInfo(e, 1);
            } finally {
              this.loadingUsers = false;
            }
            break;
          }
          case assignmentMethod.auto: {
            this.userList.push(
              findAssignmentMethod(assignmentMethod.auto)?.name
            );
            this.assignee = this.userList[0];
            break;
          }
          case assignmentMethod.self: {
            this.userList.push(
              findAssignmentMethod(assignmentMethod.self)?.name
            );
            this.assignee = this.userList[0];
            break;
          }
        }
      } catch (e) {
        console.error(e);
        this.errorAssignee = this.createErrorInfo(e, 1);
      }
    }
  },
  watch: {
    /**
     * watch task.assignment.roles
     * @return {Promise<void>}
     */
    async roles() {
      try {
        if (this.assignmentMethod === assignmentMethod.manual) {
          await this.populateUserList();
        }
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * task.assignment.method
     * @return {Promise<void>}
     */
    async assignmentMethod() {
      try {
        await this.populateUserList();
      } catch (e) {
        console.error(e);
      }
    }
  },

  /**
   * handle components mounted event
   * @return {Promise<void>}
   */
  async mounted() {
    console.log(`${this.$options.name}.mounted() this.task`, this.task);

    // populate Role List
    try {
      await this.populateRoleList();
    } catch (e) {
      console.error(e);
    }

    // populate Calendar List
    try {
      await this.populateCalendars();
    } catch (e) {
      console.error(e);
    }

    // populate User List
    try {
      await this.populateUserList();
    } catch (e) {
      console.error(e);
    }
  }
};
